/*
 * @Author: liutongtong  
 * 2019 新首页
 */
$(function () {
    var catSelect = Select.use("#J-select-cat")[0];
    catSelect.on('blur', function () {
    });
    catSelect.on('change', function () {

        if (this.val() !== '') {
            $('.J-select-cat').addClass('selected');
        } else {
            $('.J-select-cat').removeClass('selected');
        }

        if (this.val() !== '0') {
            $('.J-select-other').hide();
        } else {
            $('.J-select-other').show();
            // $('.J-select-other').find('input').focus();
        }
    });

    //slider

    (function () {
        var liNum = $('.J-mainImgSlider li'),
            liNumLen = liNum.length,
            controls = $('.J-mainImgSlider-controls li'),
            slider;

        controls.each(function (index, item) {
            $(item).attr('control-order', index);
        });

        controls.hover(function () {
            slider && slider.stopAutoplay();
            slider && slider.swipeTo($(this).attr('control-order'));
        }, function () {
            slider && slider.startAutoplay();
        });

        if (liNumLen > 1) {
            slider = new Swiper('.J-mainImgSlider', {
                autoplay: 5000,//可选选项，自动滑动
                loop: true,
                autoplayDisableOnInteraction: false,
                wrapperClass: 'main-list',
                slideClass: 'img-item',
                pagination: '.main-list-controls',
                paginationClickable: true,
                paginationElement: 'li',
                paginationActiveClass: 'selected'
            });

            $('.J-mainImgPrev').on('click', function () {
                slider.swipePrev();
            });
            $('.J-mainImgNext').on('click', function () {
                slider.swipeNext();
            });

            $('.J-mainImgSlider').find('.swiper-pagination-switch,.swiper-slide').on('hover', function (e) {
                // e.preventDefault();
                slider.stopAutoplay();
                $(e.currentTarget).click();
            }).on('mouseenter', function () {
                slider.stopAutoplay();
            }).on('mouseleave', function () {
                slider.startAutoplay();
            });

        } else {
            $('.J-mainImgPrev').remove();
            $('.J-mainImgNext').remove();
            $('.J-mainImgSlider-controls').remove();
        }
    })();

    // Prevent to load all banners at the same time
    (function () {

        function slideImgLoader() {
            var windowWidth = $(window).width();
            var el;

            if (windowWidth >= 768) {
                el = $('.J-slide-img-pc');
            } else {
                el = $('.J-slide-img-wap');
            }

            el.each(function () {
                $(this).css('background-image', 'url("' + $(this).attr('data-src') + '")');
            })
        }

        $(window).on('resize', function () {
            slideImgLoader()
        })

        slideImgLoader()
    })();

    //cate

    (function () {
        var $homeTop = $('.J-homeTop'),
            $cateMain = $('.J-cateMainList'),
            leftItem = '.J-cateLeftItem',
            popItem = '.J-catePop';

        function clear() {
            $cateMain.removeClass('open');
            $homeTop.find(leftItem).removeClass('current');
            $cateMain.find(popItem).removeClass('open');
        }

        $homeTop.on('mouseover', leftItem, function () {
            var $this = $(this);
            var index = $this.index();

            clear();

            $cateMain.addClass('open');
            $this.addClass('current');
            $cateMain.find(popItem).eq(index).addClass('open');

        }).on('mouseleave', function () {
            clear();
        })


    })();
    /*
    * PWA
    * */
    //register service worker
    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker
    //         .register('/service-worker.js', {scope:'/'})
    //         .then(function (registration) {
    //             console.log('Service Worker Registered.' + registration);
    //         }).catch(function (error) {
    //             console.log('Registration failed with.' + error);
    //         });
    // }

    // window.addEventListener('beforeinstallprompt', function (e) {
    //     e.userChoice.then(function (choiceResult) {
    //         if (choiceResult.outcome == 'dismissed') {
    //             console.log('User cancelled home screen instal');
    //         }
    //         else {
    //             console.log('User added to home screen');
    //         }
    //     });
    // });


    try {
        if (typeof Probe !== 'undefined') { new Probe().ue({ 'type': 'crovhomeAB', 'abtest': 'a' }); }
    } catch (err) { }

    $('.J-close-warning').on('click', function () {
        $('.J-warning-dialog').addClass('hide');
        $('.J-warning-mask').addClass('hide');
        window.location = '//www.crov.com/wholesale';
    })

    //主页shop now 中文字符提示
    $('.J-home-search-form').bind('submit', function (e) {
        if (!/^[\x00-\x7F]*$/.test($('.J-home-search-input').val())) {
            alert('Please input the information in English only.');
            e.preventDefault();
            return false;
        }
    });
    $('.J-input-item .J-input-text').each(function (item) {
        if ($(this).val() !== '') {
            $(this).next('.J-input-label').addClass('selected')
        };
    });
    $('.J-input-item').on('focus', '.J-input-text', function () {
        $(this).next('.J-input-label').addClass('selected');
    });
    $('.J-input-item').on('blur', '.J-input-text', function () {
        if ($(this).val() === '') {
            $(this).next('.J-input-label').removeClass('selected')
        };
    });
});